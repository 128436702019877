import React from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import moment from 'moment';
import { useNotify } from '../../../hooks/notify';
import { useLoading } from '../../../hooks/loading';

import apiAuth from '../../../services/apiAuth';

const initialState = {
    dtInicial: new Date(),
    dtFinal: new Date(),
    dsLoginAd: null,
};

const ReportAccessContext = React.createContext({
    ReportAccess: initialState,
    onChangeReportAccess: () => console.log('Not implementated yet'),
    handleSubmit: () => console.log('Not implemented yet'),
});

ReportAccessContext.displayName = "Contexto Relatório de Acesso";

const validationSchema = yup.object({
    dtInicial: yup
        .string('Digite a data Fiscal Inicial')
        //.test('is-cpf', 'CPF não é válido', (value) => validateCPF(value))
        .required('Data é obrigatório'),
    dtFinal: yup
        .string('Digite a data Fiscal Final')
        //.test('is-cpf', 'CPF não é válido', (value) => validateCPF(value))
        .required('Data é obrigatório'),
});

export function ReportAccessProvider({ children }) {
    const { onChangeNotify } = useNotify();
    const { onChangeLoading } = useLoading();

    const [ReportAccess, setReportAccess] = React.useState(initialState);
    const [oneData, setOneData] = React.useState([]);
    const [dataReal, setDataReal] = React.useState('');
    const [activeStep, setActiveStep] = React.useState(0);

    const [logoutData, setLogoutData] = React.useState([]);
    const [consultData, setConsultData] = React.useState([]);

    const formik = useFormik({
        initialValues: ReportAccess,
        validationSchema,
        onSubmit: (data, { resetForm }) => {
            handleSubmit(data, resetForm);
            setDataReal(data);
        },
    });

    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const onChangeReportAccess = (data) => {
        setReportAccess({ ...ReportAccess, ...data });
    };

    const handleSubmit = async (data, resetForm) => {
        onChangeLoading({
            open: true,
            msg: 'Consultando...',
        });

        const payload = {
            dtini: moment(data.dtInicial).format('YYYY-MM-DD 00:00:00'),
            dtfin: moment(data.dtFinal).format('YYYY-MM-DD 23:59:59'),
            user_login_ad:
                data.dsLoginAd === undefined || data.dsLoginAd === null
                    ? ''
                    : data.dsLoginAd?.toLowerCase().trim(),
        };

        await apiAuth
            .post(`log/access/query`, payload)
            .then((res) => {
                onChangeLoading({ open: false });

                if (res.status === 200) {
                    setConsultData(res.data);
                    handleNext();
                    resetForm();

                    onChangeNotify({
                        open: true,
                        class: 'success',
                        msg: `Consulta realizada com sucesso! `,
                    });
                } else {
                    resetForm();

                    onChangeNotify({
                        open: true,
                        class: 'attention',
                        msg: `Não encontramos dados! `,
                    });
                }
            })
            .catch(() => {
                onChangeLoading({ open: false });

                resetForm();

                onChangeNotify({
                    open: true,
                    class: 'error',
                    msg: 'Aconteceu algum erro, tente novamente!',
                });
            });
    };

    return (
        <ReportAccessContext.Provider
            value={{
                ReportAccess,
                onChangeReportAccess,
                consultData,
                logoutData,
                handleSubmit,
                activeStep,
                handleBack,
                handleNext,
                formik,
                dataReal,
                oneData,
            }}
        >
            {children}
        </ReportAccessContext.Provider>
    );
}

export function useReportAccess() {
    const context = React.useContext(ReportAccessContext);

    if (!context)
        throw new Error('useReportAccess must be used within an AuthProvider');

    return context;
}
