import axios from 'axios';
import config from '../config';

const apiCliente = axios.create({
	baseURL:
		process.env.REACT_APP_PROD === 'true'
			? process.env.REACT_APP_CLIENTE_PROD
			: process.env.REACT_APP_CLIENTE_HOMOLOG,
	headers: {
		'Content-Type': 'application/json',
		'x-api-key': process.env.REACT_APP_X_API_KEY,
	},
});

export default apiCliente;
