import axios from 'axios';

const apiSiv = axios.create({
	baseURL:
		process.env.REACT_APP_PROD === 'true'
			? process.env.REACT_APP_SIV_PROD
			: process.env.REACT_APP_SIV_HMG,
	headers: {
		'id-app': process.env.REACT_APP_PROD === 'true'
      ? process.env.REACT_APP_ID_PROD
      : process.env.REACT_APP_ID_HMG,
		'apikey': process.env.REACT_APP_API_KEY_SIV,
		'x-api-key': process.env.REACT_APP_X_API_KEY,
	},
});

export default apiSiv;
