import axios from 'axios';

const apiAuth = axios.create({
    baseURL:
        process.env.REACT_APP_PROD === 'true'
            ? process.env.REACT_APP_AUTH_PROD
            : process.env.REACT_APP_AUTH_HMG,
    headers: {
        'id-app': process.env.REACT_APP_PROD === 'true'
            ? process.env.REACT_APP_ID_PROD
            : process.env.REACT_APP_ID_HMG,
        apikey: process.env.REACT_APP_API_KEY_KONG,
        'X-API-KEY': process.env.REACT_APP_X_API_KEY_AUTH,
    },
});

export default apiAuth;
