import React from 'react';
import { ProductsCdcProvider } from './productsCdc';
import { ProfileManagementProvider } from './profileManagement';
import { UserManagementProvider } from './userManagement';
import { GroupStoreManagementProvider } from './groupStoreManagement';
import { ReportAbmProvider } from './ReportAbm';
import { ReportAccessProvider } from './ReportAccess';
import { ProgressiveDiscountProvider } from './progressiveDiscount';
import { RhSgiProvider } from './RhSgi';
import { ReportSisfProvider } from "./ReportSisf";
import { ReportProfileFunctionality } from "./ReportProfileFunctionality";

function ProvidersSecurity({ children }) {
    return (
        <ProductsCdcProvider>
            <ProfileManagementProvider>
                <UserManagementProvider>
                    <GroupStoreManagementProvider>
                        <ReportAbmProvider>
                            <ReportAccessProvider>
                                <ProgressiveDiscountProvider>
                                    <RhSgiProvider>
                                        <ReportSisfProvider>
                                            <ReportProfileFunctionality>
                                                {children}
                                            </ReportProfileFunctionality>
                                        </ReportSisfProvider>
                                    </RhSgiProvider>
                                </ProgressiveDiscountProvider>
                            </ReportAccessProvider>
                        </ReportAbmProvider>
                    </GroupStoreManagementProvider>
                </UserManagementProvider>
            </ProfileManagementProvider>
        </ProductsCdcProvider>
    );
}

export default ProvidersSecurity;
