import axios from 'axios';
import config from '../config'

const apiMonitorVendas = axios.create({
	baseURL:
	process.env.REACT_APP_PROD === 'true'
		? process.env.REACT_APP_MONITOR_VENDAS_PROD
		: process.env.REACT_APP_MONITOR_VENDAS_HMG,
	headers: {
		'Content-Type': 'application/json',
		'x-api-key': process.env.REACT_APP_X_API_KEY,
	},
});

export default apiMonitorVendas;
