import axios from 'axios';
import config from '../config';

const apiEnviaEmail = axios.create({
	baseURL: process.env.REACT_APP_PROD === "true"
		? process.env.REACT_APP_ENVIAEMAIL_PROD
		: process.env.REACT_APP_ENVIAEMAIL_HMG,
	headers: {
		'Content-Type': 'application/json',
		'x-api-key': process.env.REACT_APP_X_API_KEY,
		apikey: process.env.REACT_APP_API_KEY_KONG,
	},
});

export default apiEnviaEmail;
