import axios from 'axios';
import config from '../config';

const apiIdentify = axios.create({
	baseURL: process.env.REACT_APP_PROD === "true"
		? process.env.REACT_APP_IDENTIFY_PROD
		: process.env.REACT_APP_IDENTIFY_HMG,
	headers: {
		'Content-Type': 'application/json',
		'x-api-key': process.env.REACT_APP_X_API_KEY,
	},
});

export default apiIdentify;
