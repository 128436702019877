const config = {
    basename: process.env.REACT_APP_BASENAME || '/',
    app_id:
        process.env.REACT_APP_PROD === 'true'
            ? process.env.REACT_APP_ID_PROD
            : process.env.REACT_APP_ID_HMG,
    apikeykong: process.env.REACT_APP_API_KEY_KONG,
    apikeymotorsiv: process.env.REACT_APP_API_KEY_MOTORSIV,
    apikeysiv: process.env.REACT_APP_API_KEY_SIV,
    xapikey: process.env.REACT_APP_X_API_KEY,
    apikey: process.env.REACT_APP_API_KEY,
    apikeyCencopay: process.env.REACT_APP_API_KEY_CENCOPAY,
    apikeyCencopayDev: process.env.REACT_APP_API_KEY_CENCOPAY_DEV,
    apikeyFaturaS3: process.env.REACT_APP_API_KEY_FATURA_S3,
};
