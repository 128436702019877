import Cookies from 'universal-cookie';
import React, { useState } from 'react';

import apiAuth from '../services/apiAuth';

const AuthContext = React.createContext({
    signIn: () => console.log('Not implemented yet'),
    signOut: () => console.log('Not implemented yet'),
});

export function AuthProvider({ children }) {
    const cookies = new Cookies();

    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState({});
    const [isVerifying, setIsVerifying] = useState(true);

    const signIn = async ({ username, password }) => {
        setLoading(true);
        const response = await apiAuth.post('/user/login', {
            login_ad: username,
            password,
        });

        const tomorrow = new Date();
        tomorrow.setHours(tomorrow.getHours() + 8);

        cookies.set('@SF:Token', response.data.token, {
            expires: tomorrow,
        });
        localStorage.setItem('@SF:User', JSON.stringify(response.data));
        apiAuth.defaults.headers.authorization = `Bearer ${response.data.token}`;
        setLoading(false);

        setData({
            user: response.data,
            token: response.data.token,
        });
    };

    const signOut = React.useCallback(async () => {
        const User = localStorage.getItem('@SF:User');
        const userObj = JSON.parse(User);


        if (userObj) {
            const config = { headers: { 'loginad': userObj.login_ad } };

            try {
                await apiAuth.post("user/logout", {}, config);
            } catch (error) {
                console.error('Erro ao realizar logout:', error);
            }
        }

        cookies.remove('@SF:Token');
        localStorage.clear();
        apiAuth.defaults.headers.authorization = null;
        setData({});
    }, []);

    React.useEffect(() => {
        const token = cookies.get('@SF:Token');
        const user = localStorage.getItem('@SF:User');

        if (token && user) {
            const config = {
                headers: {
                    'x-access-token': token,
                },
            };

            apiAuth.get('/user/validate', config)
                .then((response) => {
                    if (response.status === 200) {
                        apiAuth.defaults.headers.authorization = `Bearer ${token}`;

                        setData({
                            user: JSON.parse(user),
                            token,
                        });
                    } else {
                        signOut();
                    }
                })
                .catch(() => {
                    signOut();
                })
                .finally(() => setIsVerifying(false));
        } else {
            setIsVerifying(false);
        }
    }, [signOut]);

    return (
        <AuthContext.Provider
            value={{ user: data.user, signIn, signOut, loading, setLoading, isVerifying }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export function useAuth() {
    const context = React.useContext(AuthContext);

    if (!context) throw new Error('useAuth must be used within an AuthProvider');

    return context;
}
