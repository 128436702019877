import axios from 'axios';
import * as yup from 'yup';
import moment from 'moment';
import { useFormik } from 'formik';
import React, { useContext, useState } from 'react';

import apiAuth from '../../../services/apiAuth';
import { useNotify } from '../../../hooks/notify';
import { useLoading } from '../../../hooks/loading';

const initialState = {
    dtInicial: new Date(),
    dtFinal: new Date(),
    // dsLoginAd: null,
    // dsLoginAdAbm: null,
    // idGroup: null
};

const ReportProfileFunctionalityContext = React.createContext({
    ReportSisf: initialState,
    onChangeReportAbm: () => console.log('Not implementated yet'),
    handleSubmit: () => console.log('Not implemented yet'),
});

ReportProfileFunctionalityContext.displayName = "Contexto Relatório Relatório Perfil x Funcionalidade"

const validationSchema = yup.object({
    dtInicial: yup
        .string('Digite a data inicial')
        // .test('is-cpf', 'CPF não é válido', (value) => validateCPF(value))
        .required('Data é obrigatório'),
    dtFinal: yup
        .string('Digite a data final')
        // .test('is-cpf', 'CPF não é válido', (value) => validateCPF(value))
        .required('Data é obrigatório'),
});

const initialStateSearch = {
    group_store: [],
};

export function ReportProfileFunctionality({ children }) {
    const { onChangeNotify } = useNotify();
    const { onChangeLoading } = useLoading();

    const [filter, setFilter] = useState();
    const [selected, setSelected] = useState();
    const [result, setResult] = useState(false);
    const [dataReal, setDataReal] = useState('');
    const [activeStep, setActiveStep] = useState(0);
    const [ReportLogsEvents, setReportLogsEvents] = useState(initialState);

    const [groupStoreData, setGroupStoreData] = useState('');

    const [storeList, setStoreList] = useState([]);
    const [profileList, setProfileList] = useState([]);
    const [consultData, setConsultData] = useState([]);
    const [groupStoreList, setGroupStoreList] = useState([]);
    const [accessGroupList, setAccessGroupList] = useState([]);
    const [profilePermissions, setProfilePermissions] = useState([]);

    const formik = useFormik({
        initialValues: ReportLogsEvents,
        validationSchema,
        onSubmit: (data, { resetForm }) => {
            handleSubmit(data, resetForm);
            setDataReal(data);
        },
    });

    const validationSchemaSearch = yup.object({
        group_store: yup.object().nullable().required('Grupo de lojas é obrigatório'),
    });

    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
        setFilter(undefined);
        formik.setFieldValue('dsLoginAd', '');
        formik.setFieldValue('idGroup', '');
    };

    const onChangeReportLogsEvents = (data) => {
        setReportLogsEvents({ ...ReportLogsEvents, ...data });
    };

    const getGroupStoreList = async () => {
        try {
            const response = await axios.get('https://v2vlw4sc-9003.brs.devtunnels.ms/api/v1/log/groupfunction');
            setGroupStoreList(response.data);
        } catch (error) {
            console.error('Erro ao consultar grupos:', error);

            // Notifica o usuário com detalhes do erro (caso seja necessário para debug)
            onChangeNotify({
                open: true,
                class: 'error',
                msg: error.response?.data?.message || 'Erro ao consultar grupos.',
            });
        }
    };


    const handleSubmit = async (data, resetForm) => {
        onChangeLoading({
            open: true,
            msg: 'Consultando...',
        });

        const payload = {
            dtini: moment(data.dtInicial).format('YYYY-MM-DD 00:00:00'),
            dtfin: moment(data.dtFinal).format('YYYY-MM-DD 23:59:59'),
            // user_login_ad: data.dsLoginAd === undefined ? '' : data.dsLoginAd,
            // user_login_ad_abm: data.idGroup === undefined ? '' : data.dsLoginAdAbm,
            // id_group: data.idGroup === undefined ? '' : data.idGroup,
        };

        try {
            const response = await apiAuth.post('group/query', payload);
            onChangeLoading({ open: false });

            if (response.status === 200) {
                setAccessGroupList(response.data);
                handleNext();
                resetForm();

                onChangeNotify({
                    open: true,
                    class: 'success',
                    msg: `Consulta realizada com sucesso!`,
                });
            } else {
                resetForm();

                onChangeNotify({
                    open: true,
                    class: 'attention',
                    msg: `Não encontramos dados!`,
                });
            }
        } catch (error) {
            onChangeLoading({ open: false });

            resetForm();

            onChangeNotify({
                open: true,
                class: 'error',
                msg: 'Aconteceu algum erro, tente novamente!',
            });
        }
    };

    const handleSelectFilter = (selected) => {
        setFilter(selected);
        formik.setFieldValue('dsLoginAd', '');
        formik.setFieldValue('idGroup', '');
    };

    const getAccessGroupList = async () => {
        await apiAuth
            .get(`group/query`)
            .then((res) => {
                setAccessGroupList(res.data);
            })
            .catch(() => {
                onChangeNotify({
                    open: true,
                    class: 'error',
                    msg: 'Erro ao consultar os grupos de acesso.',
                });
            });
    };

    const handleConsult = async () => {
        onChangeLoading({ open: true, msg: 'Consultando perfil...' });
        const settings = {
            headers: {
                'id-group': selected?.group_id,
                permissions: true,
                'id-app': process.env.REACT_APP_PROD === 'true'
                    ? process.env.REACT_APP_ID_PROD
                    : process.env.REACT_APP_ID_HMG,
            },
        };

        await apiAuth
            .get(`store/query`, settings)
            .then((res) => {
                onChangeLoading({ open: false });
                setProfilePermissions(res.data);
                setResult(true);
            })
            .catch(() => {
                onChangeLoading({ open: false });
                onChangeNotify({
                    open: true,
                    class: 'error',
                    msg: 'Aconteceu algum erro, tente novamente!',
                });
            });
    };

    const handleProfileList = async () => {
        await apiAuth
            .get('store/query')
            .then((res) => {
                setProfileList(res.data);
            })
            .catch(() => {
                onChangeNotify({
                    open: true,
                    class: 'error',
                    msg: 'Aconteceu algum erro, tente novamente!',
                });
            });
    };

    const formikSearch = useFormik({
        initialValues: initialStateSearch,
        validationSchema: validationSchemaSearch,
        onSubmit: async (data, { resetForm }) => {
            onChangeLoading({
                open: true,
                msg: 'Buscando grupo de lojas...',
            });

            const settings = {
                headers: { 'id-group-store': data.group_store.group_store_id },
            };
            await apiAuth
                .get('group-store/query', settings)
                .then((res) => {
                    onChangeLoading({ open: false });
                    setGroupStoreData(res.data);
                    getStoreList();
                    handleNext();
                    resetForm();
                    onChangeLoading({ open: false });
                    onChangeNotify({
                        open: true,
                        class: 'success',
                        msg: `Consulta realizada com sucesso! `,
                    });
                })
                .catch(() => {
                    onChangeLoading({ open: false });
                    onChangeNotify({
                        open: true,
                        class: 'error',
                        msg: 'Aconteceu algum erro, tente novamente!',
                    });
                });
        },
    });

    const getStoreList = async () => {
        await apiAuth
            .get('store/query')
            .then((res) => {
                setStoreList(res.data);
            })
            .catch(() => {
                onChangeNotify({
                    open: true,
                    class: 'error',
                    msg: 'Erro ao consultar as lojas.',
                });
            });
    };

    return (
        <ReportProfileFunctionalityContext.Provider
            value={{
                formik,
                filter,
                dataReal,
                setFilter,
                activeStep,
                handleNext,
                handleBack,
                setDataReal,
                consultData,
                setActiveStep,
                setConsultData,
                accessGroupList,
                ReportLogsEvents,
                setAccessGroupList,
                getAccessGroupList,
                handleSelectFilter,
                setReportLogsEvents,
                onChangeReportLogsEvents,

                handleConsult,
                handleProfileList,
                setSelected,
                result,
                setResult,
                profileList,
                profilePermissions,
                formikSearch,
                groupStoreData,
                storeList,

                groupStoreList,
                getGroupStoreList,
            }}
        >
            {children}
        </ReportProfileFunctionalityContext.Provider>
    )
}

export function useReportProfileFunctionality() {
    const context = useContext(ReportProfileFunctionalityContext);

    if (!context)
        throw new Error('useReportSisf must be used within an AuthProvider');

    return context;
}
