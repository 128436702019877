import React from 'react';

import { useFormik } from 'formik';
import * as yup from 'yup';
import { validateCPF } from 'validations-br';

import { useNotify } from '../../../hooks/notify';
import { useLoading } from '../../../hooks/loading';

import apiSiv from '../../../services/apiSiv';
import apiMotorSiv from '../../../services/apiMotorSiv';
import config from '../../../config';

const initialState = {
	nrCPF: '',
	newPassword: '',
};

const ControlCreditCdcContext = React.createContext({
	cdcCredit: initialState,
	onChangeCdcCredit: () => console.log('Not implemented yet'),
	handleSubmit: () => console.log('Not implemented yet'),
});

const validationSchema = yup.object({
	nrCPF: yup
		.string('Digite seu CPF')
		.test('is-cpf', 'CPF não é válido', (value) => validateCPF(value))
		.required('CPF é obrigatório'),
});

export function ControlCreditCdcProvider({ children }) {
	const { onChangeNotify } = useNotify();
	const { onChangeLoading } = useLoading();

	const [cdcCredit, setCdcCredit] = React.useState(initialState);
	const [result, setResult] = React.useState([]);
	const [proposal, setProposal] = React.useState([]);
	const [messageCredit, setMessageCredit] = React.useState(false);
	const [messageReprocess, setMessageReprocess] = React.useState(false);
	const [messageNewCredit, setMessageNewCredit] = React.useState(false);

	const [activeStep, setActiveStep] = React.useState(0);
	const [countPassword, setCountPassword] = React.useState(0);

	const token = localStorage.getItem('@SF:Token');
	const formik = useFormik({
		initialValues: cdcCredit,
		validationSchema,
		onSubmit: (data, { resetForm }) => {
			handleSubmit(data, resetForm);
		},
	});

	const handleNext = React.useCallback(() => {
		setActiveStep(activeStep + 1);
	}, [activeStep]);

	const handleBack = () => {
		setActiveStep(activeStep - 1);
	};

	const onChangeCdcCredit = (data) => {
		setCdcCredit({ ...cdcCredit, ...data });
	};

	const newcreditVerify = (response) => {
		if (response.status === 201) {
			setMessageNewCredit(true);
		}
	};

	const handleSubmit = async (data, resetForm) => {
		onChangeLoading({
			open: true,
			msg: 'Consultando CPF...',
		});

		const payload = {
			headers: {
				cpf: `${data.nrCPF.toString().replace(/\.|-/gm, '')}`
			},
		};

		await apiSiv
			.get(`order/query`, payload)
			.then((res) => {
				onChangeLoading({ open: false });
				setResult(res.data);
				handleGetNumbersPassword(res.data[0])
				handleNext();

				resetForm();

				onChangeNotify({
					open: true,
					class: 'success',
					msg: `Consulta realizada com sucesso!`,
				});
			})
			.catch(() => {
				onChangeLoading({ open: false });

				resetForm();

				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	const handleGetNumbersPassword = async (getOrder) => {
		const { idProposta, cpf } = getOrder;
		await apiMotorSiv
			.get(`/consultar-proposta/${cpf}`)
			.then((response) => {
				const dataProposal = response.data;
				const filterStatus = dataProposal.filter(
					(item) => item.cdProposta === idProposta
				);
				setCountPassword(filterStatus[0].nrTentativaSenha)
			})
			.catch(() => {
				onChangeLoading({ open: false });

				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	}

	const handleResendOrNewPassword = async (ResendOrNewPassword) => {
		onChangeLoading({
			open: true,
			msg: 'Enviando senha...',
		});

		await apiMotorSiv
			.post('/cadastrar-senha', ResendOrNewPassword)
			.then((res) => {
				onChangeLoading({ open: false });

				if (res.status === 200) {
					onChangeNotify({
						open: true,
						class: 'success',
						msg: `Senha enviada com sucesso! `,
					});
				}
			})
			.catch(() => {
				onChangeLoading({ open: false });

				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	const handleNewCreditCDC = async (data) => {
		onChangeLoading({
			open: true,
			msg: 'Enviando dados...',
		});

		await apiMotorSiv
			.post('/liberar-novo-pedido', data)
			.then((res) => {
				onChangeLoading({ open: false });
				newcreditVerify(res);
			})
			.catch(() => {
				onChangeLoading({ open: false });

				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	const handleCancelOrder = async (row) => {
		onChangeLoading({
			open: true,
			msg: 'Enviando dados...',
		});

		const objUser = JSON.parse(localStorage.getItem("@SF:User"))

		const headers = {
			headers: {
				'id-pedido': row.idPedido,
				loginAd: objUser.login_ad,
				apikey: process.env.REACT_APP_API_KEY_SIV,
			},
		};

		await apiSiv
			.post('order/cancel_order', {}, headers)
			.then((res) => {
				onChangeLoading({ open: false });
				res.status === 200 ?
				setMessageCredit(true) :
					onChangeNotify({
						open: true,
						class: 'error',
						msg: 'Aconteceu algum erro, tente novamente!',
					});
			})
			.catch(() => {
				onChangeLoading({ open: false });

				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	const handleVendapp = async (pedido) => {
		const User = localStorage.getItem("@SF:User")
		const objUser = JSON.parse(User)

		onChangeLoading({
			open: true,
			msg: 'Enviando dados...',
		});
		const headers = {
			headers: {
				'id-pedido': pedido.idPedido,
				'loginAd': objUser.login_ad,
			},
		};

		await apiSiv
			.post('order/release_order', {}, headers)
			.then((res) => {
				onChangeLoading({ open: false });
				res.status === 200
					? setMessageReprocess(true)
					: onChangeNotify({
							open: true,
							class: 'error',
							msg: 'Aconteceu algum erro, tente novamente!',
					  });
			})
			.catch(() => {
				onChangeLoading({ open: false });

				onChangeNotify({
					open: true,
					class: 'error',
					msg: 'Aconteceu algum erro, tente novamente!',
				});
			});
	};

	const consultProposal = async (cpf) => {
		await apiMotorSiv.get(`/consultar-proposta/${cpf}`).then((res) => {
			setProposal(res.data);
		});
	};

	return (
		<ControlCreditCdcContext.Provider
			value={{
				cdcCredit,
				onChangeCdcCredit,
				result,
				handleSubmit,
				handleNext,
				handleBack,
				activeStep,
				formik,
				handleResendOrNewPassword,
				handleNewCreditCDC,
				consultProposal,
				proposal,
				messageCredit,
				setMessageCredit,
				setActiveStep,
				handleCancelOrder,
				handleVendapp,
				countPassword,
				messageNewCredit,
				setMessageNewCredit,
				messageReprocess,
				setMessageReprocess
			}}
		>
			{children}
		</ControlCreditCdcContext.Provider>
	);
}

export function useControlCreditCdc() {
	const context = React.useContext(ControlCreditCdcContext);

	if (!context)
		throw new Error('useControlCreditCdc must be used within an AuthProvider');

	return context;
}
