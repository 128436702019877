import axios from 'axios';
import config from '../config';

const apiDescontoProgressivo = axios.create({
	baseURL:
		process.env.REACT_APP_PROD === 'true'
			? process.env.REACT_APP_DESCONTO_PROGRESSIVO_PROD
			: process.env.REACT_APP_DESCONTO_PROGRESSIVO_HOMOLOG,
	headers: {
		'Content-Type': 'application/json',
		'x-api-key':
			process.env.REACT_APP_PROD === 'true'
				? process.env.REACT_APP_X_API_KEY
				: '7NrqjTKpp45GxcBGmHl0v5rjaCGZYhNO82J9C7SJ',
	},
});

export default apiDescontoProgressivo;
