import axios from 'axios';
import config from '../config';

const apiMotorSiv = axios.create({
	baseURL: process.env.REACT_APP_PROD === "true"
		? process.env.REACT_APP_MOTOR_SIV_PROD
		: process.env.REACT_APP_MOTOR_SIV_HMG,
	mode: 'no-cors',
	headers: {
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
		'Access-Control-Allow-Headers':
			'Origin, X-Request-Width, Content-Type, Accept',
		apikey: process.env.REACT_APP_API_KEY_MOTORSIV,
	},
});

export default apiMotorSiv;
